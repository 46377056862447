<template>
  <div class="shopping">
    <Header></Header>
    <Nav></Nav>
    <div class="crumb-box">
      <div class="contaner">
        <el-row class="nav-box" style="height: 81px" type="flex" align="middle">
          <!-- <span class="pos" style="opacity: 0">您现在的位置：</span> -->
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>机床商城</el-breadcrumb-item>
            <el-breadcrumb-item>商品列表</el-breadcrumb-item>
          </el-breadcrumb> -->
          <!-- <span class="pos">您现在的位置：</span> -->
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">
              <span class="c9">首页</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'shopping' }"
              ><span class="c3" style="font-weight: 400"
                >机床商城</span
              ></el-breadcrumb-item
            >
            <el-breadcrumb-item
              ><span class="c3">结算</span></el-breadcrumb-item
            >
          </el-breadcrumb>
        </el-row>
      </div>
    </div>

    <div class="content-box">
      <div class="contaner">
        <div class="mark-add-order-box">
          <img src="@/assets/images/right.png" alt="" />
          <p class="l1">订单支付成功!</p>
          <p class="l2">应支付金额：<span>￥ 207.40</span></p>
          <p class="l3">支付时间：2019-12-06 12:00</p>
          <div class="btn">
            <div class="b1">查看订单</div>
            <div class="b2">返回首页</div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 32px; background: #f8f8f8"></div>
    <Footer></Footer>
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    return {
      // 选择条数数据
      value: "5",
      pageSizeOptions: [
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "15",
          label: "15",
        },
      ],

      // 顶部的筛选按钮
      activeIndex: 0,

      // 价格的排序方式 0-升序 1-降序
      sortType: 1,

      // 表格数据
      tableData: [],
      goodType: [],
      typeId: "", //分类对应id
      total: 0, //列表总数
      page: 1,
      pageSize: 5,
      size: 5,
      // pageSizeArr: [5, 10, 15],
    };
  },
  created() {
    // 二手商品-查询机床类目
    this.getGoodSort();
    // 二手商品列表
    this.getGoodList();
  },
  methods: {
    // 二手商品列表
    getGoodList() {
      // // 价格的排序方式 0-升序 1-降序
      let categoryOneId = this.typeId ? Number(this.typeId) : "";
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        isAsc: this.sortType == 1 ? false : true, //是否升序  true升序   false降序
        categoryOneId, //分类id
      };
      this.$API
        .getGoodList(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.total = Number(res.data.total);
            this.tableData = [];
            this.tableData = res.data.records;
            window.scrollTo(0, 0);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 类目改变
    changeType() {
      // console.log(this.typeId);
      this.getGoodList();
    },
    // 二手商品-查询机床类目
    getGoodSort() {
      let paramData = {
        categoryType: 2,
        parentId: 0,
      };
      this.$API
        .getGoodSort(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.goodType = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 顶部的筛选按钮点击操作
    handleChange(index) {
      // if (index === this.activeIndex && index === 1) {
      //   this.sortType = this.sortType === 0 ? 1 : 0;
      //   return;
      // }
      this.sortType = this.sortType === 0 ? 1 : 0;
      this.activeIndex = index;
      this.getGoodList();
    },

    // 每页展示条数改变操作
    handleSizeChange(size) {
      // 每页条数改变
      // console.log(size);
      // console.log("改变了", size);
      this.pageSize = Number(size);
      this.size = Number(size);
      this.getGoodList();
    },

    // 页码改变操作
    handleCurrentChange(page) {
      this.page = page;
      this.getGoodList();
      // console.log(page, "页码改变");
    },
  },
};
</script>

<style lang="scss">
.el-select {
  width: 90px;
}
.el-pagination .el-input__suffix {
  right: 22px;
  top: 5px;
  transform: scale(0.8);
}
.el-pagination .el-select .el-input .el-input__inner {
  width: 80px !important;
  height: 30px !important;
}
.shopping .el-table th.el-table__cell > .cell {
  color: #000;
}
.el-input__inner:focus {
  color: #333333 !important;
}
.type-select {
  margin-left: 20px;
  width: 200px;
  height: 40px;
  color: #333333;
  .el-select {
    width: 200px;
  }
}
/* 全局样式 */
/* 重置单元格样式 */
.el-table.shop-list-table td.el-table__cell .cell {
  padding-left: 0;
  padding-right: 0;
}
.el-table.shop-list-table .el-table__body-wrapper .el-table__row:hover td {
  background-color: #fff;
}
.price-column-style {
  vertical-align: top !important;
}
/* 重置element-ui分页的激活页码颜色 */
.shop-list-pagination.el-pagination.is-background
  .el-pager
  li:not(.disabled):hover {
  color: #ed1b23;
}
.shop-list-pagination.el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #ed1b23;
  color: #fff;
}

/* 重置分页的样式 */
.shop-list-pagination
  .el-pagination__sizes
  .el-input
  .el-input__inner.el-input__inner:hover {
  border-color: #ed1b23;
}
.shop-list-poper-class
  .el-select-dropdown__wrap
  .el-select-dropdown__item.selected {
  color: #ed1b23;
}

/* 重置跳转页码的样式 */
.shop-list-pagination .el-input.is-active .el-input__inner,
.el-input__inner:focus {
  color: #ed1b23;
  border-color: #ed1b23;
}
</style>
<style scoped lang="scss">
.text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.disabled-text-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

/* 顶部面包屑导航 */
.crumb-box {
  height: 81px;
  line-height: 81px;
  background-color: #f8f8f8;
  border-top: 1px solid #ed1b23;
}

.content-box {
  background-color: #f8f8f8;
  .contaner {
    padding: 24px 32px;
    padding-bottom: 0;
    background-color: #fff;

    // 顶部的筛选按钮
    .top-box {
      display: flex;
      .btn-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 36px;
        border-radius: 6px 6px 6px 6px;
        text-align: center;
        line-height: 36px;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        background-color: #f5f5f5;
        cursor: pointer;

        .btn-item-icon {
          margin-left: 8px;
          display: flex;
          flex-direction: column;

          .el-icon-caret-bottom {
            margin-top: -8px;
            color: #bfbfbf;
          }

          i.active {
            color: #333;
          }
        }

        &.active {
          background: #ed1b23;
          color: #fff;

          i {
            color: #bfbfbf;
          }

          i.active {
            color: #fff;
          }
        }
      }
    }

    // 列表 - element-ui表格版本
    .table-box {
      border: 0 none;
      .left {
        //   width: 70%;
        width: 600px;
        display: flex;

        .shop-img {
          width: 160px;
          height: 160px;
        }

        .shop-info {
          // align-self: flex-start;
          margin-left: 20px;
          // padding-top: 8rpx;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 25px;
        }
      }

      .right {
        .shop-price {
          text-align: center;
          padding-top: 8px;
          color: #ed1b23;
          font-size: 20px;
          font-weight: bold;

          .text1 {
            font-size: 14px;
          }
          .text2 {
            padding-left: 4px;
          }
        }
      }
    }

    // 自定义列表版本样式
    // // 表格区域
    // .table-box {
    //   border: 0 none;

    //   .table-row {
    //     display: flex;
    //     justify-content: space-between;
    //     padding: 20px 0;
    //     border-bottom: 1px solid #eeeeee;

    //     &.table-head {
    //       font-size: 20px;
    //       font-weight: 400;
    //       color: #000000;
    //       line-height: 46px;
    //       text-align: center;

    //       .left {
    //         display: block !important;
    //       }
    //     }

    //     .left {
    //       //   width: 70%;
    //       width: 600px;
    //       display: flex;

    //       .shop-img {
    //         width: 160px;
    //         height: 160px;
    //       }

    //       .shop-info {
    //         align-self: flex-start;
    //         margin-left: 20px;
    //         padding-top: 8rpx;
    //         font-size: 16px;
    //         font-weight: bold;
    //         color: #333333;
    //         line-height: 25px;
    //       }
    //     }

    //     .right {
    //       width: 20%;

    //       .shop-price {
    //         text-align: center;
    //         padding-top: 8px;
    //         color: #ed1b23;
    //         font-size: 20px;
    //         font-weight: bold;

    //         .text1 {
    //           font-size: 14px;
    //         }
    //         .text2 {
    //           padding-left: 4px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

//  底部分页
// .el-select {
//   width: 80px !important;
//   height: 30px !important;
// }

// .el-pagination .el-select .el-input .el-input--suffix {
//   width: 80px !important;
//   height: 30px !important;
// }

// .el-pagination .el-input {
//   width: 80px !important;
//   height: 30px !important;
// }
.pagination-box {
  .select-size {
    display: inline-block;
    margin-top: -1px;
    color: #333;
    font-weight: 400 !important;
  }
  padding-top: 40px;
  padding-bottom: 87px;
  background-color: #f8f8f8;
  .contaner {
    display: flex;
    justify-content: flex-end;
  }
  .total-num {
    margin-top: 7px;
    margin-right: 15px;
  }
}

.mark-add-order-box {
  height: 638px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 54px;
    height: 54px;
    margin-bottom: 20px;
  }

  .l1 {
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    margin-bottom: 24px;
  }

  .l2 {
    font-weight: 500;
    font-size: 13px;
    color: #333333;
    margin-bottom: 12px;
    span {
      color: #ff1327;
    }
  }

  .l3 {
    font-size: 12px;
    color: #999999;
    margin-bottom: 56px;
  }

  .btn {
    display: flex;
    cursor: pointer;
    .b1 {
      width: 120px;
      height: 32px;
      background: #ed1b23;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin-right: 16px;
    }
    .b2 {
      width: 120px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dddddd;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
